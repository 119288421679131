export class EndPoints {
  public static readonly CUSTOMER_COMPLAINT = 'customer-complaint';
  public static readonly COMPLAINT = 'complaint';
  public static readonly PROCESS = 'process';
  public static readonly COMPLAINT_CITY = 'complaint/find/city/'; 
  public static readonly PROCESS_CITY = 'process/find/city/'; 
  public static readonly COMPLAINT_NOT_CITY = 'complaint/list/notAssigned';
  public static readonly PROCESS_NOT_CITY = 'process/list/notAssigned';
  public static readonly LIST_CITY_BY_DATE = 'complaint/find/cityAndDate';
  public static readonly LIST_NOT_ASSIGNED_BY_DATE = 'complaint/list/notAssignedByDate';
  public static readonly COMPLAINT_LIST_INSTANCE = 'complaint/find/instance/';
  public static readonly ARCHIVED = 'archived'
  public static readonly BETTERGETPROCESS = 'betterGetProcess'
  public static readonly BETTERGETCOMPLAINTS = 'betterGetComplaints'
  public static readonly SANCTIONED = 'sanctioned'
  public static readonly PUBLIC = 'public'
  public static readonly SEARCH = '/show/';
  public static readonly UPLOAD_FILES = '/upload-files/';
  public static readonly LISTS = 'lists';
  public static readonly LIST_GENDER = '/1';
  public static readonly LIST_TYPE_DOCUMENT = '/2';
  public static readonly LIST_SPECIES = '/3';
  public static readonly LIST_LIFE_STATUS = '/4';
  public static readonly LIST_CITY = '/5';
  public static readonly LIST_TYPE_ESTABLISHMENT = '/6';
  public static readonly LIST_TYPE_PROFESIONAL = '/7';
  public static readonly LIST_MODULES = '/8';
  public static readonly LIST_ROLE = '/9';
  public static readonly LIST_COLLEGES = '/10';
  public static readonly LIST_MAGISTERS = 'magister/firtinstance';
  public static readonly LIST_LAWYER_SUSTAN = 'lawyer/sustanciador';
  public static readonly LIST_SUSTANCIADORES = 'list/sustanciadores';
  public static readonly LIST_PARTICIPANTS = 'list/participants';
  public static readonly USERS = 'users';
  public static readonly USERSCOMPLAINTS = 'userscomplaints';
  public static readonly LOGIN = 'login';
  public static readonly RECOV_PASS = 'recovery-password';
  public static readonly VAL_RESET_PASS = 'validate-request-reset';
  public static readonly RESET_PASS = 'reset-password';
  public static readonly PERMISSIONS = 'permissions';
  public static readonly EXTERNALUSER = 'createExternalUser';
  public static readonly PROCEDURE = 'procedure';
  public static readonly CHANGE_PROCEDURE = 'procedure/changeStateManaged'
  public static readonly STATE = 'state';
  public static readonly STAGE = 'stage';
  public static readonly SUB_STAGE = 'substage';
  public static readonly ROLE = '/role';
  public static readonly REPORTS = 'reports';
  public static readonly EXPIRATIONS = 'expirations';
  public static readonly EXPIRATIONS_NUMBERS = 'expirations-numbers';
  public static readonly EXPIRATIONS_NUMBERS_ROL_25 = 'complaint/list/count/instance/0/'
  public static readonly EXPIRATIONS_NUMBERS_ROL_1285 = 'complaint/list/count/city/'
  public static readonly TASK = 'task';
  public static readonly LIST_TRAMIT_BYDATE = 'complaint/show/filterByDate';
  public static readonly DOWNLOAD_REPORT = 'complaint/export-procedure/all/complaints'
  public static readonly DOWNLOAD_REPORT_BYCITY = 'complaint/export-procedure/city'
  public static readonly DOWNLOAD_REPORT_NOTCITY = 'complaint/export-procedure/not/Associated'
  public static readonly RETURN = 'return'
  public static readonly ACTIVE = 'active'
  public static readonly CHANGE_TO_PROCESS = 'changeToProcess'
  public static readonly DELETELASTFOLIO = '/deletelastfolio/'
  public static readonly ADD_SUSTANCIADOR = 'addsustanciador'
  public static readonly CHANGE_LAWYER = 'changeLawyer'
  public static readonly GET_ACTIVE_LAWYER = 'getActiveLawyer'
  public static readonly LIST_ACTIVE_BY_USER = 'listActiveByUser'

}
