import { Injectable } from '@angular/core';
import { WrapperService } from '../wrapper/wrapper.service';
import { Observable } from 'rxjs';
import { EndPoints } from 'src/app/constants/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  constructor(
    private _api: WrapperService
  ) { }

  createComplaint(body: Object): Observable<any> {
    return this._api.post(EndPoints.CUSTOMER_COMPLAINT, body);
  }

  getComplaint(id: string): Observable<any> {
    return this._api.get(EndPoints.COMPLAINT + EndPoints.SEARCH + id);
  }

  getComplaintDetail(id: number): Observable<any> {
    return this._api.get(`${EndPoints.COMPLAINT}/${id}`);
  }

  saveFiles(id: string, body: Object): Observable<any> {
    return this._api.post(EndPoints.COMPLAINT + EndPoints.UPLOAD_FILES + id, body);
  }

  getProcess(page: number): Observable<any> {
    return this._api.get(EndPoints.PROCESS);
  }

  betterGetProcess(page,size,filter): Observable<any> {
    return this._api.get(`${EndPoints.PROCESS}/${EndPoints.BETTERGETPROCESS}/${page}/${size}/${filter}`);
  }

  getProcessCity(id: number): Observable<any> {
    return this._api.get(EndPoints.PROCESS_CITY + id);
  }

  
  getProcessNotCity(instance: number,page,size,filter): Observable<any> {
    return this._api.get(`${EndPoints.PROCESS_NOT_CITY}/${instance}/${page}/${size}/${filter}`);
  }

  getComplaintsInstance(instance: number): Observable<any> {
    return this._api.get(EndPoints.COMPLAINT_LIST_INSTANCE + instance);
  }

  getProcessArchived(page,size,filter): Observable<any> {
    return this._api.get(`${EndPoints.PROCESS}/${EndPoints.ARCHIVED}/${page}/${size}/${filter}`);
  }

  getComplaintsSanctioned(): Observable<any> {
    return this._api.get(EndPoints.SANCTIONED);
  }

  getComplaintsPublicByUser(id): Observable<any> {
    return this._api.get(`${EndPoints.PUBLIC}/${id}`);
  }

  getSanctionedById(id): Observable<any> {
    return this._api.get(`${EndPoints.SANCTIONED}/${id}`);
  }


  archiveUnarchive(id: number) {
    return this._api.delete(`${EndPoints.ARCHIVED}/${id}`);
  }

  updateComplaint(id: number, comp: Object): Observable<any> {
    return this._api.post(`${EndPoints.COMPLAINT}/${id}`, comp);
  }

  downloadReport(id: number){
    return window.open(`${this._api.baseUrl + EndPoints.DOWNLOAD_REPORT}`, 'Download')
  }

  downloadReportByCity(id_city: string){
    return window.open(`${this._api.baseUrl + EndPoints.DOWNLOAD_REPORT_BYCITY}/${id_city}`, 'Download')
  }

  dowunloadReportNotAssociated (){
    return window.open(`${this._api.baseUrl + EndPoints.DOWNLOAD_REPORT_NOTCITY}`, 'Download')
  }

  returnComplaint(id: number) {
    return this._api.get(`${EndPoints.RETURN}/${id}`);
  }

  getComplaintsCityAndDate(id: number,date): Observable<any> {
    return this._api.get(`${EndPoints.LIST_CITY_BY_DATE}/${id}/${date}`);
  }

  
  getComplaintsNotCityAndDate(date): Observable<any> {
    return this._api.get(`${EndPoints.LIST_NOT_ASSIGNED_BY_DATE}/${date}`);
  }

  deleteLastFolio(id: number): Observable<any> {
    return this._api.get(EndPoints.COMPLAINT + EndPoints.DELETELASTFOLIO + id);
  }

  addSustanciador(dto : any){
    return this._api.post(EndPoints.PROCESS+'/'+EndPoints.ADD_SUSTANCIADOR,dto);
  }
  changeLawler(dto : any){
    return this._api.post(EndPoints.PROCESS+'/'+EndPoints.CHANGE_LAWYER,dto);
  }

  getProcessActiveByUser(id : number,page,size,filter){
    return this._api.get(`${EndPoints.PROCESS}/${EndPoints.LIST_ACTIVE_BY_USER}/${id}/${page}/${size}/${filter}`);
  }

}
